import React from 'react';
import { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css'; // Import Masonry
import CardSingle from './CardSingle';
import { Form} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { AlertModalProvider } from './AlertModalContext';
import StartHereModal from './StartHereModal';
import StartHereAlert from './StartHereAlert';


export default function CardGridFAQs({ allData, setAllData, siteTitle, siteTitlePretty }) {
const [allFaqData, setAllFaqData] = useState([]);

let searchFaqResults = allData?.filter(item => item.type === "faqs" && item.filter_category === true).sort((a, b) => a.random_number - b.random_number);;
let faqTotal = searchFaqResults.length
let dropdownCategory = ["Biology", "Communication", "Existentialism", "Identity", "Morality"]
const [newSelectedCategories, setNewSelectedCategories] = useState([]);

console.log()
console.log(searchFaqResults)

const handleCheckboxChange = (event) => {
    const value = event.target.value;
    console.log("Checkbox changed:", value); // Debugging
  
    setSelectedCategories(prevSelectedCategories => {
      console.log("Previous categories:", prevSelectedCategories); // Debugging
  
      let newSelectedCategories = prevSelectedCategories.includes(value) 
        ? prevSelectedCategories.filter(category => category !== value)
        : [...prevSelectedCategories, value];
  
      console.log("New categories:", newSelectedCategories); // Debugging
  
      filterCategory(newSelectedCategories); // Pass the updated categories to filterCategory
      return setNewSelectedCategories(newSelectedCategories);
    });
  };
  
 
  function filterCategory(selectedCategories) {
    console.log("function argument:", selectedCategories)
    
    // Check if 'All' category is selected or no category is selected
    const isAllSelected = selectedCategories.includes("All") || selectedCategories.length === 0;

    let filteredCategoryArray = searchFaqResults.map(dataItem => {
        // Set filter_category to true if 'All' is selected or if the item's category is in the selected categories
        if (isAllSelected || selectedCategories.includes(dataItem.category)) {
            return { ...dataItem, filter_category: true };
        }
        return { ...dataItem, filter_category: false };
    });
    // console.log("filter category array", filteredCategoryArray);
    setAllData(filteredCategoryArray);
    console.log("cat filter", searchFaqResults)
  }

  const [selectedCategories, setSelectedCategories] = useState(dropdownCategory); // Initialize with the selected categories


    return (
        <div>
          <Helmet>
          <title>{siteTitlePretty.seo_title}</title>
          <meta name="description" content={siteTitlePretty.seo_description} />
          </Helmet>

        <div className='grid-header'>
        <h2>{siteTitlePretty.title}</h2><p>{siteTitlePretty.description}</p>
        </div>


    
        <div className="card-grid-container">
    
        <AlertModalProvider>
      <StartHereAlert />
      <StartHereModal/>
    </AlertModalProvider>

        <div className="leading-people-header">
     <h1>Frequently asked questions </h1>
     <p className='leading-people-description'>Questions that I hear often, either from myself or the people in my orbit. I'm not afraid to change my mind when presented with new information. </p>
           </div>

   
          <Masonry
            breakpointCols={2}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {searchFaqResults.map(dataObject => (
              <div key={dataObject.unique_key} className="masonry-item">
                <CardSingle siteTitle={siteTitle} dataObject={dataObject} />
              </div>
            ))}
          </Masonry>
        

        </div>
        <p className='copyright-footer'>Our lawyers are very eager to  point out that Phillip Reyland is not for sale and does not come with any form of manufacturer’s warranty because he’s an actual human person, and that would be extremely silly.  Support requests for malfunctioning Phillip Reylands, including parts replacements, will not be processed but are very likely to be printed out and hung on his refrigerator. </p>
    
        <p className='copyright-footer'>&copy; Copyright 2024 Phillip Reyland</p>
      </div>
      );}