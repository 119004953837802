import React, { createContext, useState, useContext, useEffect } from 'react';

const AlertModalContext = createContext();

export const useAlertModal = () => useContext(AlertModalContext);

export const AlertModalProvider = ({ children }) => {
  // Check localStorage for previous state
  const hasAlertBeenClosed = localStorage.getItem('alertClosed') === 'true';
  const hasModalBeenClosed = localStorage.getItem('modalClosed') === 'true';
  
  const [isAlertOpen, setAlertOpen] = useState(!hasAlertBeenClosed);
  const [isModalOpen, setModalOpen] = useState(!hasModalBeenClosed);

  useEffect(() => {
    if (!isAlertOpen) {
      localStorage.setItem('alertClosed', 'true');
    }
    if (!isModalOpen) {
      localStorage.setItem('modalClosed', 'true');
    }
  }, [isAlertOpen, isModalOpen]);

  const openAlert = () => setAlertOpen(true);
  const closeAlert = () => setAlertOpen(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <AlertModalContext.Provider value={{ isAlertOpen, openAlert, closeAlert, isModalOpen, openModal, closeModal }}>
      {children}
    </AlertModalContext.Provider>
  );
};
