import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './App.css';
import { useEffect, useState, useCallback, createContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, Outlet } from 'react-router-dom';
import { Navbar, Nav, Offcanvas, Button, Form, InputGroup, Container } from 'react-bootstrap';
import { Link, Navigate, useNavigate, useLocation  } from 'react-router-dom';
import ContactForm from './components/ContactForm';
import CardGrid from './components/CardGrid';
import CardGridSearch from './components/CardGridSearch';
import CardFilters from './components/CardFilters';
import CardGridFAQs from './components/CardGridFAQs';
import { AlertModalProvider } from './components/AlertModalContext';


function App() {

const [allData, setAllData] = useState([]);
const [siteTitle, setSiteTitle] = useState('');
const [siteTitlePretty, setSiteTitlePretty] = useState( {title: '', description: '', seo_title: '', seo_description: '', seo_keywords: '' });
const [openHowToUse, setOpenHowToUse] = useState(false);
const [isOpen, setIsOpen] = useState(false);
const [show, setShow] = useState(false);
const [isDataLoaded, setIsDataLoaded] = useState(false);
const [currentSearchTerm, setCurrentSearchTerm] = useState('sklskljskj');
const [currentSearchValue, setCurrentSearchValue] = useState('');

const navigate = useNavigate();
const location = useLocation();
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const AlertModalContext = createContext();

useEffect(() => {
  const fetchData = async () => {
    try {
      const url = "/db.json";
      const response = await fetch(url);
      let newData = await response.json();

      if (newData.ReylandDotCom) {
        newData = newData.ReylandDotCom;
      }

      const reylandData = newData.map(item => ({
        ...item,
        filter_topic: true,
        filter_category: true,
        filter_search: false,
        random_number: Math.random(),
        unique_key: Math.random(),
      }));

      setAllData(reylandData);
      setIsDataLoaded(true); // Set isDataLoaded to true after data is loaded
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);

 function filterTopic(topic) {
    // console.log("Ran a topic filter!")
    let newAllData = allData.map(data => {
        const searchFlag = topic === "" || data.type.toLowerCase().includes(topic.toLowerCase());
        return {...data, filter_topic: searchFlag};
    });
    if (JSON.stringify(allData) !== JSON.stringify(newAllData)) {
        setAllData(newAllData);
    }
    setSiteTitle(topic);
    determinePrettyTitle(siteTitle);

}


  // let search = allData?.filter(results => results.filter_topic)
  let search = allData?.filter(results => results.filter_topic && results['card-type'] !== 'header' && results['card-type'] !== 'footer');
  let searchResults = allData?.filter(results => results.filter_search === true)
  let searchHeader = allData?.filter(results => results.filter_topic && results['card-type'] === 'header');
  let searchFooter = allData?.filter(results => results.filter_topic && results['card-type'] === 'footer');
  

  // let searchFaqs = allData
  // ?.filter(results => results.filter_topic === true && results.filter_category === true)
  // .sort((a, b) => a.random_number - b.random_number);
  // console.log("searchFAQs", searchFaqs)

  function determinePrettyTitle(siteTitle) {

      let prettyTitle = '';
      let prettyDescription = '';
      let seoTitle = '';
      let seoDescription = '';
      let seoKeywords = '';
  
      if (siteTitle.startsWith('Search:')) {
        const query = siteTitle.substring(7); // Removes 'search:' from the beginning
        prettyTitle = `Search`;
        prettyDescription = `We hope you find whatever it is you're searching for.`;
        seoTitle = `Search: ${query} - Phillip Reyland - New York, NY`;
        seoDescription = `Find information related to "${query}"`;
        seoKeywords = query; // or any other relevant keywords
        // prettyTitle = `Search Results for "${query}"`;
        // prettyDescription = `Showing results for "${query}"`;
        // seoTitle = `Search: ${query} - Phillip Reyland - New York, NY`;
        // seoDescription = `Find information related to "${query}"`;
        // seoKeywords = query; // or any other relevant keywords

      } else if (siteTitle === 'product-features') {
        prettyTitle = 'Product Features';
        prettyDescription = 'Congratulations on your recent purchase';
        seoTitle = 'User Manual: Phillip Reyland - Founder and Technologist, New York, NY';
        seoDescription = 'I’m a builder at heart who loves working on complicated systems and impossible problems. Software is my playground of choice, though I’ve also never been shy with a hot glue gun.';
        seoKeywords = '';
       
      } else if (siteTitle === 'assemblage') {
        prettyTitle = 'Assemblage';
        prettyDescription = 'Important information about how your new product is assembled';
        seoTitle = 'How my brain works - Phillip Reyland - New York, NY';
        seoDescription = 'I like to think about how I think. An ego-free look at how my brain works and how I like to process and store information.';
        seoKeywords = '';
     
      } else if (siteTitle === 'creating-ideas') {
        prettyTitle = 'Creating Ideas';
        prettyDescription = 'Great for developing ideas and building products';
        seoTitle = 'How I generate and build ideas - Phillip Reyland - New York, NY';
        seoDescription = 'I’m not the least bit afraid of embarrassing myself in pursuit of a really big problem or a super weird solution.';
        seoKeywords = '';
      
      } else if (siteTitle === 'leading-people') {
        prettyTitle = 'Leading People';
        prettyDescription = 'Great for building culture and leading teams';
        seoTitle = 'How I build and lead teams - Phillip Reyland - New York, NY';
        seoDescription = 'Humans are not capital, and output is not a leading indicator of contribution. You don\'t have to be a jerk to inspire great work.';
        seoKeywords = '';
      
      } else if (siteTitle === 'safety-features') {
        prettyTitle = 'Safety Features';
        prettyDescription = 'Our most reliable and secure product ever';
        seoTitle = 'How I find and manifest safety - Phillip Reyland - New York, NY';
        seoDescription = 'The immense level of curiosity and commitment I expect from my teams is only possible if I provide a safe space in which to operate.';
        seoKeywords = '';

      } else if (siteTitle === 'maintenance') {
        prettyTitle = 'Maintenance';
        prettyDescription = 'Tips and tricks for getting the most out of your new product';
        seoTitle = 'Some tips on how to collaborate with me - Phillip Reyland - New York, NY';
        seoDescription = 'Done right, collaborating can get weird and messy. Below are some tricks for working with or utilizing me as a resource. You can corrupt me with good coffee.';
        seoKeywords = '';
         
      } else if (siteTitle === 'important-warnings') {
        prettyTitle = 'Warnings';
        prettyDescription = 'Not intended for use as a flotation device. Please read carefully.';
        seoTitle = 'Let\'s celebrate my failures - Phillip Reyland - New York, NY';
        seoDescription = 'Nothing is certain in life accept death, taxes, and failure. I\'m kind of an expert at it.';
        seoKeywords = '';
        
      } else if (siteTitle === 'faqs') {
        prettyTitle = 'FAQs';
        prettyDescription = 'Frequently asked questions about your new product.';
        seoTitle = 'Frequently asked questions - Phillip Reyland - New York, NY';
        seoDescription = 'Questions that I hear often, either from myself or the people in my orbit. I\'m not afraid to change my mind when presented with new information.';
        seoKeywords = '';
      }
        return { title: prettyTitle, description: prettyDescription, seo_title: seoTitle, seo_description: seoDescription, seo_keywords: seoKeywords};
      };
      
      console.log(siteTitlePretty)

      useEffect(() => {
        const newSiteTitlePretty = determinePrettyTitle(siteTitle);
        setSiteTitlePretty(newSiteTitlePretty);
        // console.log(siteTitlePretty); 
      }, [siteTitle]);
      
      function RoutingFunction() {
        const { topic, query } = useParams();
        const navigate = useNavigate();
        const location = useLocation();
      
        const isFAQsRoute = location.pathname === '/faqs';
      
        if (!isDataLoaded) {
          // Data is not loaded yet, you can return a loading indicator or null
          return null;
        }
      
        if (isFAQsRoute) {
          setSiteTitle("faqs");
          return (
            <CardGridFAQs siteTitle={siteTitle} siteTitlePretty={siteTitlePretty} allData={allData} setAllData={setAllData} />
          );
        } else if (topic) {
          filterTopic(topic);
          setCurrentSearchValue('');
          return (
            <CardGrid
              search={search}
              searchResults={searchResults}
              searchHeader={searchHeader}
              searchFooter={searchFooter}
              siteTitle={siteTitle}
              siteTitlePretty={siteTitlePretty}
            />
          );
        } else if (query) {
          setCurrentSearchValue(query);
          console.log("search value being passed on routing", query);
          return (
            <CardGridSearch 
              allData={allData}
              setAllData={setAllData}
              setSiteTitle={setSiteTitle}
              setCurrentSearchTerm={setCurrentSearchTerm}
              currentSearchValue={currentSearchValue}
              currentSearchTerm={currentSearchTerm}
              searchResults={searchResults}
              siteTitle={siteTitle}
              siteTitlePretty={siteTitlePretty}
            />
          );
        } else {
          return null;
        }
      }

    
  useEffect(() => {
    // Function to extract query parameters
    const searchParams = new URLSearchParams(window.location.search);
    const path = searchParams.get('path');

    // If there's a path parameter, navigate to it
    if (path) {
        navigate(path);
        
    }
}, [navigate]);



  const expand = 'lg';
 

      
  return (
    <div className="App">

      <Container id="table-of-contents">
      <Nav.Link eventKey="1" as={Link} to="product-features">
      <img src={process.env.PUBLIC_URL + '/img/reyland_header.jpg'} alt="Reyland Header" className="responsive-image" />
      </Nav.Link>
      
        <CardFilters shouldRender={true} setCurrentSearchValue={setCurrentSearchValue} setCurrentSearchTerm={setCurrentSearchTerm} currentSearchValue={currentSearchValue}  setSiteTitle={setSiteTitle} filterTopic={filterTopic}/>

      </Container>
      <Container className="content-middle">


      <header className="site-header">
        <div className="user-manual">USER<br></br> MANUAL</div>
        <div className="header-info">
        <Nav.Link eventKey="1" as={Link} to="product-features">
        <h1>Phillip Reyland <span className="version">(v40.0)</span></h1>
        </Nav.Link>
          <p className="subtitle">Founder and Technologist</p>
        </div>
        <div className='ps-offcanvas'>
      <Button variant="primary" onClick={handleShow} className="sticky-button">
      <img src={process.env.PUBLIC_URL + '/img/help.svg'} alt="Reyland Header" className="product-support-image" />
      </Button>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className='product-support-header'>
        <img src={process.env.PUBLIC_URL + '/img/help.svg'} alt="Reyland Header" className="product-support-image" />
        <h4>Product Support</h4>
        </div>
        <div className='offcanvas-content'>
        <ContactForm />
        </div>
        </Offcanvas.Body>
      </Offcanvas>
      </div>
        <Navbar collapseOnSelect key={expand} expand={expand} className="bg-body-tertiary mb-3">
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
        <div className="nav-offcanvas">
        <Navbar.Offcanvas
        className="nav-header"
        id={`offcanvasNavbar-expand-${expand}`}
        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
        placement="end">
          
        <Offcanvas.Header closeButton></Offcanvas.Header>
        {/* <img src={process.env.PUBLIC_URL + '/img/reyland-dot-com.jpg'} alt="Reyland Header" className="offcanvas-nav-header" />
      
         */}
        <div className='mobile-nav-filters'>

        <CardFilters />
        </div>
        </Navbar.Offcanvas>
        </div>
        </Navbar>
      </header>

    
    
    <Routes location={location}>
    {/* <TransitionGroup>
    <CSSTransition key={location.key} timeout={250} classNames="fade"> */}
      <Route path="/:topic" element={<RoutingFunction />} />
      {/* </CSSTransition>
      </TransitionGroup> */}
        <Route path="/faqs" element={<RoutingFunction />} />
        <Route path="/" element={<Navigate to="/product-features" replace />} />
        <Route path="/search" element={<Navigate to="/product-features" replace />} />
        <Route path="/search/:query" element={<RoutingFunction />} />
    </Routes>
  


   
      </Container>
      <Container className="product-support">
      
      <div className='barcode'><img src={process.env.PUBLIC_URL + '/img/barcode.png'} alt="Reyland Header" className="responsive-image" /></div>

        
         <div className='product-support-header'>
        <img src={process.env.PUBLIC_URL + '/img/help.svg'} alt="Reyland Header" className="product-support-image" />
        <h4>Product Support</h4>
        </div>
        <div className='product-support-container'>
        <ContactForm />
        </div>
      </Container>
    </div>
  );
}
export default App;