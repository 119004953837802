import React from 'react';
import Card from 'react-bootstrap/Card';
import { Accordion } from 'react-bootstrap'; // Import only Accordion

export default function CardSingle({ dataObject, siteTitle, className }) {
  const cardStyleClass = className === 'search-result' ? "card-style-search" : `card-style-${dataObject['card-style']}`;


  const renderAccordionItems = (accordionData) => {
    return accordionData.map((item, index) => (
      <Accordion.Item eventKey={`${index}`} flush>
        <Accordion.Header>
          <div className='accordion'>
            <h4>{item.title}</h4>
            {item.failureSpectrum && <span>Failure Spectrum: <strong>{item.failureSpectrum}</strong></span>}
          </div>
        </Accordion.Header>
        <Accordion.Body>
        <p dangerouslySetInnerHTML={{ __html: item.content }} />
          
        </Accordion.Body>
      </Accordion.Item>
    ));
  };

  return (
    <div className="grid-item">
      <Card className={cardStyleClass} style={{ width: '100%' }}>
        <Card.Body>

        {(className === 'search-result') && (<p className='search-label'>{dataObject.label}</p>)}
        {(siteTitle === "faqs" || className === 'search-result' && dataObject.type === "faqs" ) && (<p className='question'>Question</p>)}
          <h2>{dataObject.title}</h2>          
          {/* {siteTitle.includes("search:") && (<p>SEARCH RESULT</p>)} */}
           
          {(siteTitle === "faqs" || className === 'search-result' && dataObject.type === "faqs" ) && (<p className='question'>Answer</p>)}
          <p dangerouslySetInnerHTML={{ __html: dataObject.description }} />
          {dataObject.accordion && (
            <Accordion>
              {renderAccordionItems(dataObject.accordion)}
            </Accordion>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
