import React from 'react';
import { Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Masonry from 'react-masonry-css';
import CardSingle from './CardSingle';

export default function GridFooter({ siteTitle, searchFooter }) {

  return (
    <div>
    {siteTitle === "creating-ideas" && (
    <div>
      
      <Masonry
        breakpointCols={1}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {searchFooter.map(dataObject => (
          <div key={dataObject.unique_key} className="masonry-item">
            <CardSingle siteTitle={siteTitle} dataObject={dataObject} />
          </div>
        ))}
      </Masonry>
      
  </div>
  )}



    </div>
  );
}




