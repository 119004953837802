import React, { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css'; // Import Masonry
import CardSingle from './CardSingle';
import { Pagination } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, Navigate, useNavigate, useLocation  } from 'react-router-dom';
import { AlertModalProvider } from './AlertModalContext';
import StartHereModal from './StartHereModal';
import StartHereAlert from './StartHereAlert';

export default function CardGridSearch({ setAllData, setSiteTitle, query, allData, searchResults, siteTitle, siteTitlePretty, currentSearchTerm, setCurrentSearchTerm, currentSearchValue }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // Set the number of items per page as desired
  const totalPages = Math.ceil(searchResults.length / itemsPerPage);
  const navigate = useNavigate();
  // const [searchCards, setSearchCards] = useState([]);


  useEffect(() => {
    const filterSearch = (currentSearchValue) => {
      let searchValue = currentSearchValue + "";
      let searchTerm = currentSearchTerm + "";
      console.log("FilterSearchRun: Value" + searchValue);
      console.log("FilterSearchRun: Term " + searchTerm);
  
      const stripHtml = (html) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || "";
      };
  
      if (searchValue === "") {
        navigate("/product-features");
      } else if (searchValue !== searchTerm) {
        console.log("value does not match term");
        setCurrentSearchTerm(searchValue);
  
        let filterSearchArray = allData?.map((datas) => {
          const titleText = stripHtml(datas.title);
          const descriptionText = stripHtml(datas.description);
  
          return (
            titleText.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
            descriptionText.toLowerCase().includes(currentSearchValue.toLowerCase())
          )
            ? { ...datas, filter_search: true }
            : { ...datas, filter_search: false };
        });
  
        // Update searchCards here, outside the map function
        setAllData(filterSearchArray);
  
        console.log("time of search update", allData);
  
        if (typeof currentSearchValue !== "string") {
          navigate(`/search/${searchValue}`);
        }
      }
    };
  
    if (currentSearchValue) {
      filterSearch(currentSearchValue);
      console.log("searchvalue", currentSearchValue);
      console.log("searchterm", currentSearchTerm);
      console.log("use effect for search value run");
      setSiteTitle(`Search:${currentSearchValue}`);
    }
  }, []);

 
let searchCards = allData?.filter(results => results.filter_search === true)


  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    console.log('Page number clicked:', pageNumber); // Debugging line
    setCurrentPage(pageNumber);
  };
  
  // Calculate the range of data to show on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = searchCards.slice(indexOfFirstItem, indexOfLastItem);

  // Generate pagination items
  let paginationItems = [];
  for (let number = 1; number <= totalPages; number++) {
    paginationItems.push(
      <Pagination.Item 
        key={number} 
        active={number === currentPage} 
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  let resultsNumber = searchResults.length

  return (
    <div>
      <Helmet>
        <title>{siteTitlePretty.seo_title}</title>
        <meta name="description" content={siteTitlePretty.seo_description} />
      </Helmet>
      <div className='grid-header'>
        <h2>{siteTitlePretty.title}</h2>
        <p>{siteTitlePretty.description}</p>
      </div>

  
      <div className="card-grid-container">

      <AlertModalProvider>
      <StartHereAlert />
      <StartHereModal/>
    </AlertModalProvider>
        <div className="leading-people-header">
          <h1>Searching for "{currentSearchTerm}" </h1>
        </div>
        <div className='filter-faqs-container'>
          <p>Results: {resultsNumber} </p>
        </div>
  
        {resultsNumber === 0 ? (
          <div className="no-results-message">
            <p>Womp womp. No results found. </p>
          </div>
        ) : (
          <>
            <Pagination className='search-pagination'>{paginationItems}</Pagination>
            <Masonry
              breakpointCols={1}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column">
              {currentItems.map(dataObject => (
                <div key={dataObject.unique_key} className="masonry-item">
                  <CardSingle className={'search-result'} siteTitle={siteTitle} dataObject={dataObject} />
                </div>
              ))}
            </Masonry>
            <Pagination className='search-pagination'>{paginationItems}</Pagination>
          </>
        )}
      </div>
    </div>
  );}
