import React from 'react';
import { Button, Alert } from 'react-bootstrap';
import { useAlertModal } from './AlertModalContext'; // Import useAlertModal here

export default function StartHereAlert() {
    const { isAlertOpen, closeAlert, openModal } = useAlertModal();

    if (!isAlertOpen) return null;

    return (
        <Alert className='get-started-here' onClose={closeAlert} dismissible>
            <Alert.Heading>
                <h3>Get started here</h3>
                <p>A user manual for an adult human? <em>Absurd</em>.</p>
            </Alert.Heading>
            <Button className="hdmi" variant="primary" onClick={openModal}>⇨ Learn More</Button>
        </Alert>
    );
};
