import React, { useState, useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form, Button } from 'react-bootstrap';

const ContactForm = () => {
  const form = useRef();
  const recaptchaRef = useRef();
  const [emailSent, setEmailSent] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [isValidName, setIsValidName] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidMessage, setIsValidMessage] = useState(false);
  const [contactReason, setContactReason] = useState('');
  const [showCaptcha, setShowCaptcha] = useState(false);

  const handleCaptchaChange = (value) => {
    // Logic for handling captcha value change
  };

  useEffect(() => {
    setShowCaptcha(isValidName && isValidEmail && isValidMessage && contactReason);
  }, [isValidName, isValidEmail, isValidMessage, contactReason]);


  const validateName = (e) => {
    const name = e.target.value;
    setIsValidName(name.trim().length > 0);
  };

  const validateEmail = (e) => {
    const email = e.target.value;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    setIsValidEmail(regex.test(email));
  };

  const validateMessage = (e) => {
    const message = e.target.value;
    setIsValidMessage(message.trim().length > 0);
  };

  const handleReasonChange = (e) => {
    setContactReason(e.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!isValidName || !isValidEmail || !isValidMessage || !contactReason || !recaptchaValue) {
      // Add your error handling logic here if validation fails
      return;
    }

    emailjs.sendForm('service_9qykl6g', 'template_ocbbx5r', form.current, 'Ma_mLvfc48hHtGawv')
      .then((result) => {
        setEmailSent(true);
        setFeedbackMessage('Your message has been successfully sent. Someone from our team will reach out to you soon.');
        form.current.reset();
      }, (error) => {
        setEmailSent(false);
        setFeedbackMessage('Failed to send email. Please try again.');
      });
  };

  return (
    <div>
      {!emailSent ? (
        <Form ref={form} onSubmit={sendEmail} className='product-support-form'>
          <Form.Group className="mb-3">
            <Form.Label>Full Name</Form.Label>
            <Form.Control required type="text" name="user_name" onChange={validateName} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control required type="email" name="user_email" onChange={validateEmail} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Reason for Contacting</Form.Label>
            <Form.Select aria-label="Select a reason" name="reason" onChange={handleReasonChange}>
              <option value="">Select a reason</option>
              <option value="inquiry">General Inquiry</option>
              <option value="media">Media Inquiry</option>
              <option value="support">Support</option>
              <option value="feedback">Feedback</option>
              <option value="other">Other</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" >
            <Form.Label>Message</Form.Label>
            <Form.Control required as="textarea" name="message" onChange={validateMessage} />
          </Form.Group>

          {showCaptcha && (
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LcsUS8pAAAAAHLlB-2oIsvEfONkmORD4R9eOFe1"
              onChange={handleCaptchaChange}
            />
          )}
          <div className='product-support-form-button'>
            <Button variant="primary" type="submit" disabled={!isValidName || !isValidEmail || !isValidMessage || !contactReason}>Submit</Button>
          </div>
        </Form>
      ) : (
        <div className="feedback-message">{feedbackMessage}</div>
      )}
    </div>
  );
};

export default ContactForm;
