import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Collapse, Form, InputGroup } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import CardGridSearch from './CardGridSearch';


export default function CardFilters({ handleSearch, currentSearchValue, setCurrentSearchValue, setSiteTitle} ) {
  const [openHowToUse, setOpenHowToUse] = useState(true);
  const navigate = useNavigate();

  function handleSearch(event) {
    setCurrentSearchValue(event.target.value)
      if (event.target.value === '') {
        navigate('/product-features');
      } else {
        navigate(`/search/${event.target.value}`);
        setSiteTitle(`Search:${event.target.value}`);
      }
    };

  return (
    
    <div className="table-of-contents">
    <ul><li></li>
      <Form className='search-form' onSubmit={(event) => {
      event.preventDefault();
    }}><li> 
      <Form.Group align="left" controlId="formSearch">
        <InputGroup>
          <InputGroup.Text id="basic-addon2">      <img src={process.env.PUBLIC_URL + '/img/search_icon.svg'} alt="Reyland Header" className='search-icon'/></InputGroup.Text>
          <Form.Control
            type="search"
            placeholder="Search"
            value={currentSearchValue}
            onChange={(event) => handleSearch(event)}
            style={{
              '::placeholder': {
                color: 'red', // Change the color to your desired color
                fontStyle: 'italic', // You can also change the font style
              },
            }}
          />
        </InputGroup>
      </Form.Group>
      </li>
    </Form>
      <li id="product-features"><Nav.Link eventKey="1" as={Link} to="product-features">Product Features</Nav.Link></li>
      
      <li>
        <button className="table-of-contents" onClick={() => {
          setOpenHowToUse(!openHowToUse);
        }}>
          How to Use <span className={`arrow ${openHowToUse ? 'up' : 'down'}`}>⇩</span>
        </button>
        <Collapse in={openHowToUse}>
            <ul>
              <li className="nav-link" id="creating-ideas"><Nav.Link as={Link} eventKey="2" to="creating-ideas"><span> →</span> Creating Ideas</Nav.Link></li>
              <li id="leading-people"><Nav.Link eventKey="3" as={Link} to="leading-people"><span> →</span> Leading People</Nav.Link></li>
            </ul>
          </Collapse>
        </li>
        <li id="assemblage"><Nav.Link as={Link} eventKey="4" to="assemblage">Assemblage</Nav.Link></li>
        <li id="safety-features"><Nav.Link as={Link} eventKey="5" to="safety-features">Safety Features</Nav.Link></li>
        <li id="maintenance"><Nav.Link as={Link} eventKey="6" to="maintenance">Maintenance</Nav.Link></li>
        <li id="important-warnings"><Nav.Link as={Link} eventKey="7" to="important-warnings">Warnings</Nav.Link></li>
        <li id="faqs"><Nav.Link as={Link} eventKey="8" to="faqs">FAQs</Nav.Link></li>

  

      
      
      </ul>
   
    </div>
  );
}