import React from 'react';
import { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css'; // Import Masonry
import CardSingle from './CardSingle';
import { Alert, Button, Form} from 'react-bootstrap';
import GridHeader from './GridHeader';
import GridFooter from './GridFooter';
import StartHereModal from './StartHereModal';
import StartHereAlert from './StartHereAlert';
import { AlertModalProvider } from './AlertModalContext';
import { Helmet } from 'react-helmet';

export default function CardGrid({ search, searchHeader, searchFooter, siteTitle, siteTitlePretty, dropdownCategory, filterCategory }) {

  const [show, setShow] = useState(true);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [showModal, setShowModal] = useState(false);


  const getBreakpointColumns = (siteTitle) => {
    // Check if the siteTitle is "creating-ideas"
    if (siteTitle === "product-features") {
      // Return a specific configuration for "creating-ideas"
      return {
        default: 2, // For example, 3 columns by default
        2000: 2,
        1800: 1,
        1300: 1,
        1200: 1,
        // ... other breakpoints specific to "creating-ideas"
      };
    } 
    else if (siteTitle === "maintenance") {
      // Return a specific configuration for "creating-ideas"
      return {
        default: 2, // For example, 3 columns by default
        2000: 2,
        1800: 2,
        1200: 2,
        800: 1
        // ... other breakpoints specific to "creating-ideas"
      };
    } 
    
    
    else {
      // Universal breakpoint columns configuration for other cases
      return {
        default: 2,
        1800: 2,
        1200: 1,
      };
    }
  };
  
  // Usage
  const breakpointColumnsObj = getBreakpointColumns(siteTitle);
  

  
  return (
    <div>
      <Helmet>
      <title>{siteTitlePretty.seo_title}</title>
      <meta name="description" content={siteTitlePretty.seo_description} />
      </Helmet>
    <div className='grid-header'>
    <h2>{siteTitlePretty.title}</h2><p>{siteTitlePretty.description}</p>
    </div>

    <div className="card-grid-container">

    <AlertModalProvider>
      <StartHereAlert />
      <StartHereModal/>
    </AlertModalProvider>



<GridHeader siteTitle={siteTitle} searchHeader={searchHeader}/>

    {siteTitle !== "faqs" && (
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {search.map(dataObject => (
          <div key={dataObject.unique_key} className="masonry-item">
            <CardSingle siteTitle={siteTitle} dataObject={dataObject} />
          </div>
        ))}
      </Masonry>
          )}

    
    <GridFooter siteTitle={siteTitle} searchFooter={searchFooter} />

    </div>
    <p className='copyright-footer'>Our lawyers are very eager to  point out that Phillip Reyland is not for sale and does not come with any form of manufacturer’s warranty because he’s an actual human person, and that would be extremely silly.  Support requests for malfunctioning Phillip Reylands, including parts replacements, will not be processed but are very likely to be printed out and hung on his refrigerator. </p>

    <p className='copyright-footer'>&copy; Copyright 2024 Phillip Reyland</p>
  </div>
  );}