import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useAlertModal } from './AlertModalContext'; // Import the useAlertModal hook here

export default function StartHereModal() {
    
  const { isModalOpen, closeModal } = useAlertModal();

  return (
   <Modal show={isModalOpen} onHide={closeModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
     
     <Modal.Title className="modal-title-custom">
        <button 
          className="modal-close-button btn-close float-right" 
          onClick={closeModal} // Use closeModal from context
          aria-label="Close"
        ></button>
        <img src="/img/start-here.jpg" alt="Header Image" style={{ width: '100%' }} />
     </Modal.Title>

     <Modal.Body>
     
            <h1>Full money-back guarantee.</h1>
            <p>A User Manual is undoubtedly a weird thing for someone to write about themselves, and I fully welcome your response to the content and thinking I’ve included in mine. Should you choose to embark on this adventure with me, It might be useful to understand a few things about my nature and why I felt so compelled to write, design, engineer, and publish this site. So, here we go:
              </p>
            
            <h3>This is an experiment.</h3>
            <p>I’ve toiled endlessly at the content, tone, design, and website experience in an effort to describe myself and my thinking accurately. I’ve worried about my intent, vulnerability, and need to be exhaustive. For a moment, I even let myself worry about what you might think of me. This project was a weird and curious journey, and at its heart is a single, important question: <em>What if I put most of my cards on the proverbial table?</em> Yes, I’ve left some of the most personal and traumatic details of my life out, but the omission is not because they bring me shame or I’m afraid to tell you about them; it’s simply because my participation in this experiment does not automatically permit me to document the roles of others. This is not an exercise in holding others accountable; it’s about who I am, what I believe, and what I want to bring into the world. And despite having no real idea how people may view or use it, I know that both risk and ambiguity are necessary ingredients for doing something interesting. <em>I really like doing interesting things</em>. </p>

            <h3>I’m generally low-ego.</h3>
            <p>I do not take myself or my thinking too seriously, which is most evident by the pesky wrinkles around my eyes from laughing at myself. This does not imply that I’m not a serious person; when I commit, I tend to go all the way. But by and large, I do not derive self-worth from my individual efforts or talents. I sincerely understand where I am most useful and where I am not, and I view all contributions as valuable. I really want to build cool things with cool people, and I learned early in my career that nothing destroys collaboration quicker than ego.  Simply put, I have my own drum, and you have yours; both are equally effective at annoying the neighbors, even if we like to play different songs.
                 </p>
            
            <h3>I want to know myself.</h3>
            <p>It’s taken me eons to discover that real strength lies in vulnerability, and the best way I could think to challenge my own assumptions about myself was to painstakingly document everything I knew on the one topic I’d rather avoid. One of the unexpected and complicated results of this document was having to asses and fact-check the stories I’d learned to tell about myself, my accomplishments, and even my lived realities. While I believe these stories were mostly a by-product of a complicated childhood, it feels incredible to say that this document is, in no way, shape, or form, propaganda I’ve created to trick you into liking, loving or even hating me. It is entirely for me. And while I may have published it for others to read, even that effort is about me wanting to be seen and understood, not about your reaction.</p>

            <h3>I like a challenge.</h3>
            <p>I’ve never written anything that was more than a few pages long. I’ve also never designed something on the fly using responsive CSS or built a React.js single-page app from scratch. And while I’ve never felt insecure about my engineering or writing skills, I’d be lying if I said that I’ve never thought of myself as particularly good at either. I’m happy to report that I’m much better now than when I began assembling it. </p>

            </Modal.Body>
    </Modal>
    );
  }
  
